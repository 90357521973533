import "./_style.less";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import Menu from "antd/es/menu";
import Dropdown from "antd/es/dropdown";
import {
  BellOutlined,
  CheckOutlined,
  CloseOutlined,
  KeyOutlined,
  LogoutOutlined,
  MenuOutlined,
  UserOutlined,
} from "@ant-design/icons";
import Space from "antd/es/space";
import Avatar from "antd/es/avatar/avatar";
import Typography from "antd/es/typography";
import Drawer from "antd/es/drawer";
import { getAliasesName } from "../../../utils/helpers";
import VuiSidebar from "../../../components/sidebar";
import VuiBreadcrumb from "../../../components/breadcrumb";
import { useAuth } from "../../../context/auth";
import { useTranslation } from "react-i18next";
import { generateMenu } from "../../../constants/menu";
import { Badge, Empty, Switch, Tooltip } from "antd";
import AccountRepository from "../../../repositories/account-repository";
import { AxiosResponse } from "axios";
import {
  NotificationResource,
  Notification,
} from "../../../models/notification";
import Paragraph from "antd/es/typography/Paragraph";
import moment from "moment";
import VuiLoadingScreen from "../../../components/loading-screen";

const { Text } = Typography;

const VuiHeader = () => {
  const { t } = useTranslation();
  const { state, logout } = useAuth();
  const menus = generateMenu(t);
  const [showDrawerMenu, setShowDrawerMenu] = useState<boolean>(false);
  const [showNotification, setShowNotification] = useState(false);
  const [unread, setUnread] = useState(false);
  const [notification, setNotification] = React.useState<Notification[]>([]);
  const [loadingNotification, setLoadingNotification] =
    React.useState<boolean>(false);
  const [activeNotification, setActiveNotification] = useState<string>("");

  const handleCloseDrawerMenu = useCallback(() => {
    setShowDrawerMenu(false);
  }, []);

  const showNotificationBell = useMemo<boolean>(() => {
    let show = false;
    for (let item of notification || []) {
      if (!item.read_at) {
        show = true;
        break;
      }
    }

    return show;
  }, [notification]);

  const getNotifications = useCallback(async () => {
    setNotification([]);
    setLoadingNotification(true);
    try {
      let params: any = {
        per_page: 99999,
      };
      if (unread) {
        params["type"] = "unread";
      }

      const res: AxiosResponse<NotificationResource> =
        await AccountRepository.notification(params);
      setNotification(res.data.data);
      setLoadingNotification(false);
    } catch (err) {
      console.log(err);
      setLoadingNotification(false);
    }
  }, [unread]);

  const handleReadNotification = useCallback(async (id: number | string) => {
    setLoadingNotification(true);

    try {
      await AccountRepository.showNotification(id);
      getNotifications().catch();
    } catch (err) {
      console.log(err);
      setLoadingNotification(false);
    }
  }, []);

  useEffect(() => {
    if (state.phase === "auth" && state.user) {
      getNotifications().catch();
    }
  }, [state.phase, state.user, unread]);

  return (
    <>
      <header className="vui-header">
        <div className="brand-wrapper">
          <div className="hide-lg">
            {state.phase === "auth" ? (
              <>
                <MenuOutlined
                  className="menu-icon"
                  style={{
                    fontSize: 18,
                  }}
                  onClick={() => setShowDrawerMenu(true)}
                />

                <Drawer
                  title={
                    <div className="drawer-logo-mobile-wrapper">
                      <img src={"/assets/images/logo.svg"} alt="logo" />
                    </div>
                  }
                  placement={"left"}
                  closable={true}
                  onClose={handleCloseDrawerMenu}
                  visible={showDrawerMenu}
                  className="header-menu-drawer"
                >
                  <VuiSidebar
                    menus={menus}
                    mode="inline"
                    onChange={handleCloseDrawerMenu}
                  />
                </Drawer>
              </>
            ) : null}
          </div>

          <Link to={"/"}>
            <img className="logo" src={"/assets/images/logo.svg"} alt="logo" />
          </Link>
        </div>

        <div className="show-lg">
          <VuiBreadcrumb />
        </div>

        <div className="profile-wrapper">
          {state.phase === "auth" && state.user ? (
            <>
              <Badge dot={showNotificationBell}>
                <BellOutlined
                  style={{ fontSize: 20 }}
                  onClick={() => setShowNotification(true)}
                />
              </Badge>

              <div className="auth-wrapper">
                <Space className="auth-spaces" size={15}>
                  <Dropdown
                    overlayClassName={"main-header-dropdown"}
                    overlay={
                      <Menu>
                        <Menu.Item key="photo" className="avatar-menu-item">
                          <div className="avatar-wrapper">
                            <Avatar
                              size="large"
                              className="mb6"
                              src={state.user?.photo?.url}
                            >
                              {getAliasesName(state.user?.name)}
                            </Avatar>
                            <Text strong>{state.user?.name}</Text>
                          </div>
                        </Menu.Item>

                        <Menu.Divider />

                        <Menu.Item key="profile">
                          <Link to={"/profile"}>
                            <UserOutlined className="icon" />
                            {t("common.text.viewProfile")}
                          </Link>
                        </Menu.Item>

                        <Menu.Item key="change-password">
                          <Link to={"/change-password"}>
                            <KeyOutlined className="icon" />
                            {t("common.text.changePassword")}
                          </Link>
                        </Menu.Item>

                        <Menu.Divider />

                        <Menu.Item key="logout">
                          <div onClick={logout}>
                            <LogoutOutlined className="icon" />
                            {t("common.text.logout")}
                          </div>
                        </Menu.Item>
                      </Menu>
                    }
                    trigger={["click"]}
                  >
                    <div>
                      <Avatar size="large" src={state.user?.photo?.url}>
                        {getAliasesName(state.user?.name)}
                      </Avatar>
                    </div>
                  </Dropdown>
                </Space>
              </div>
            </>
          ) : null}
        </div>
      </header>

      <Drawer
        title={t("common.text.notification")}
        closable={true}
        placement="right"
        onClose={() => setShowNotification(false)}
        visible={showNotification}
        className="header-notification-drawer"
        key={"notification-drawer"}
      >
        <div className="action-wrapper">
          <div className="switch-wrapper">
            <span>{t("common.text.onlyShowUnread")}</span>
            <Switch
              size={"small"}
              checkedChildren={<CheckOutlined />}
              unCheckedChildren={<CloseOutlined />}
              defaultChecked={false}
              onChange={() => {
                setUnread(!unread);
                console.log(unread);
              }}
            />
          </div>
        </div>

        <div className="main-notif-wrapper">
          <div className="segment-wrapper">
            <div className="notif-list">
              {loadingNotification && <VuiLoadingScreen />}

              {!loadingNotification &&
                notification.map((data: any) => {
                  let item = data || {};
                  return (
                    <div
                      className="notif-item"
                      key={data.id}
                      onClick={() => {
                        setActiveNotification(data.id);
                        if (!data.read_at) {
                          handleReadNotification(data.id).catch();
                        }
                      }}
                    >
                      {item.avatar?.name && (
                        <Avatar src={item.avatar?.url}>
                          {getAliasesName(item.avatar?.name)}
                        </Avatar>
                      )}

                      <div className="info-wrapper">
                        <Paragraph className="info-title">
                          {item.data.subject}
                        </Paragraph>

                        <Paragraph
                          className="info-description"
                          ellipsis={{
                            rows: activeNotification === data.id ? 2 : 999,
                          }}
                        >
                          {item.data.message}
                        </Paragraph>

                        <label className="info-timestamp">
                          {moment(item.created_at).format("DD MMM YYYY HH:mm")}
                        </label>
                      </div>

                      {!data.read_at && (
                        <div
                          className="status-wrapper"
                          onClick={() => handleReadNotification(data.id)}
                        >
                          <Tooltip
                            placement="left"
                            title={t(
                              data.read_at
                                ? "header.notification.markUnread"
                                : "header.notification.markRead"
                            )}
                          >
                            <span
                              className={`status ${
                                data.read_at ? "" : "unread"
                              }`}
                            ></span>
                          </Tooltip>
                        </div>
                      )}
                    </div>
                  );
                })}

              {!loadingNotification && notification.length === 0 && (
                <div className="empty-wrapper">
                  <Empty description={"There is no notification"} />
                </div>
              )}
            </div>
          </div>
        </div>
      </Drawer>
    </>
  );
};

export default VuiHeader;

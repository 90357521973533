export const ENV = {
  getTinyApiKey: () => window._env_.REACT_APP_TINY_API_KEY,
  getApiEndPoint: () => window._env_.REACT_APP_PASSPORT_END_POINT,
  getPassportClientId: () => window._env_.REACT_APP_PASSPORT_CLIENT_ID,
  getPassportClientSecret: () => window._env_.REACT_APP_PASSPORT_CLIENT_SECRET,
  getHostAppUrl: () => window._env_.REACT_APP_HOST_APP_URL,
  getAppName: () => window._env_.REACT_APP_NAME,
};

export const LOCALSTORAGE_FILTER_DATA_KEY = {
  continents: "filter:continents",
  countries: "filter:countries",
  cities: "filter:cities",
  categories: "filter:categories",
  resorts: "filter:resorts",
  offers: "filter:offers",
  journeys: "filter:journeys",
  status: "filter:status",
};

export const DEFAULT_BANNER_TEXT_COLOR = "#ffffff";

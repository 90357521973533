import { RouteObject } from "react-router-dom";
import React from "react";
import AppLayout from "../layouts/app";
import VuiLoadingScreen from "../components/loading-screen";

const SettingCompany = React.lazy(() => import("../pages/setting/company"));
const SettingUserList = React.lazy(() => import("../pages/setting/user/list"));
const SettingUserForm = React.lazy(() => import("../pages/setting/user/form"));
const SettingRoleList = React.lazy(() => import("../pages/setting/role/list"));
const SettingRoleForm = React.lazy(() => import("../pages/setting/role/form"));
const Dashboard = React.lazy(() => import("../pages/dashboard"));
const Profile = React.lazy(() => import("../pages/profile"));
const ChangePassword = React.lazy(() => import("../pages/change-password"));
const PageList = React.lazy(() => import("../pages/page/list"));
const PageForm = React.lazy(() => import("../pages/page/form"));
const DestinationList = React.lazy(
  () => import("../pages/destination/destination/list")
);
const DestinationForm = React.lazy(
  () => import("../pages/destination/destination/form")
);
const JourneyList = React.lazy(
  () => import("../pages/destination/journey/list")
);
const JourneyForm = React.lazy(
  () => import("../pages/destination/journey/form")
);
const BestExperienceList = React.lazy(
  () => import("../pages/destination/best-experience/list")
);
const BestExperienceForm = React.lazy(
  () => import("../pages/destination/best-experience/form")
);
const WhenTimeToVisitList = React.lazy(
  () => import("../pages/destination/when-time-to-visit/list")
);
const WhenTimeToVisitForm = React.lazy(
  () => import("../pages/destination/when-time-to-visit/form")
);
const ContinentList = React.lazy(
  () => import("../pages/master/continent/list")
);
const ContinentForm = React.lazy(
  () => import("../pages/master/continent/form")
);
const CountryList = React.lazy(() => import("../pages/master/country/list"));
const CountryForm = React.lazy(() => import("../pages/master/country/form"));
const CityList = React.lazy(() => import("../pages/master/city/list"));
const CityForm = React.lazy(() => import("../pages/master/city/form"));
const PartnerList = React.lazy(() => import("../pages/master/partner/list"));
const PartnerForm = React.lazy(() => import("../pages/master/partner/form"));
const CommunityList = React.lazy(
  () => import("../pages/master/community/list")
);
const CommunityForm = React.lazy(
  () => import("../pages/master/community/form")
);
const WhyExperienceList = React.lazy(
  () => import("../pages/master/why-experience/list")
);
const WhyExperienceForm = React.lazy(
  () => import("../pages/master/why-experience/form")
);
const ServiceList = React.lazy(() => import("../pages/master/service/list"));
const ServiceForm = React.lazy(() => import("../pages/master/service/form"));
const CustomerExperienceList = React.lazy(
  () => import("../pages/master/customer-experience/list")
);
const CustomerExperienceForm = React.lazy(
  () => import("../pages/master/customer-experience/form")
);
const OfferList = React.lazy(() => import("../pages/offer/offer/list"));
const OfferForm = React.lazy(() => import("../pages/offer/offer/form"));
const OfferCategoryList = React.lazy(
  () => import("../pages/offer/category/list")
);
const OfferCategoryForm = React.lazy(
  () => import("../pages/offer/category/form")
);
const ContactLuxuryResortList = React.lazy(
  () => import("../pages/contact-form/luxury-resort/list")
);
const ContactLuxuryResortForm = React.lazy(
  () => import("../pages/contact-form/luxury-resort/form")
);
const ContactOfferList = React.lazy(
  () => import("../pages/contact-form/offer/list")
);
const ContactOfferForm = React.lazy(
  () => import("../pages/contact-form/offer/form")
);

const ContactJourneyList = React.lazy(
  () => import("../pages/contact-form/journey/list")
);
const ContactJourneyForm = React.lazy(
  () => import("../pages/contact-form/journey/form")
);
const LuxuryResortList = React.lazy(() => import("../pages/luxury-resort/list"));
const LuxuryResortForm = React.lazy(() => import("../pages/luxury-resort/form"));

export const appRoutes: RouteObject[] = [
  {
    path: "/",
    element: <AppLayout />,
    children: [
      {
        index: true,
        element: (
          <React.Suspense fallback={<VuiLoadingScreen />}>
            <Dashboard />
          </React.Suspense>
        ),
      },
      {
        path: "profile",
        element: (
          <React.Suspense fallback={<VuiLoadingScreen />}>
            <Profile />
          </React.Suspense>
        ),
      },
      {
        path: "change-password",
        element: (
          <React.Suspense fallback={<VuiLoadingScreen />}>
            <ChangePassword />
          </React.Suspense>
        ),
      },
      {
        path: "destination",
        children: [
          {
            path: "",
            element: (
              <React.Suspense fallback={<VuiLoadingScreen />}>
                <DestinationList />
              </React.Suspense>
            ),
          },
          {
            path: "add",
            element: (
              <React.Suspense fallback={<VuiLoadingScreen />}>
                <DestinationForm />
              </React.Suspense>
            ),
          },
          {
            path: ":id",
            element: (
              <React.Suspense fallback={<VuiLoadingScreen />}>
                <DestinationForm />
              </React.Suspense>
            ),
          },
          {
            path: "journey",
            children: [
              {
                path: "",
                element: (
                  <React.Suspense fallback={<VuiLoadingScreen />}>
                    <JourneyList />
                  </React.Suspense>
                ),
              },
              {
                path: "add",
                element: (
                  <React.Suspense fallback={<VuiLoadingScreen />}>
                    <JourneyForm />
                  </React.Suspense>
                ),
              },
              {
                path: ":id",
                element: (
                  <React.Suspense fallback={<VuiLoadingScreen />}>
                    <JourneyForm />
                  </React.Suspense>
                ),
              },
            ],
          },
          {
            path: "best-experience",
            children: [
              {
                path: "",
                element: (
                  <React.Suspense fallback={<VuiLoadingScreen />}>
                    <BestExperienceList />
                  </React.Suspense>
                ),
              },
              {
                path: "add",
                element: (
                  <React.Suspense fallback={<VuiLoadingScreen />}>
                    <BestExperienceForm />
                  </React.Suspense>
                ),
              },
              {
                path: ":id",
                element: (
                  <React.Suspense fallback={<VuiLoadingScreen />}>
                    <BestExperienceForm />
                  </React.Suspense>
                ),
              },
            ],
          },
          {
            path: "when-time-to-visit",
            children: [
              {
                path: "",
                element: (
                  <React.Suspense fallback={<VuiLoadingScreen />}>
                    <WhenTimeToVisitList />
                  </React.Suspense>
                ),
              },
              {
                path: "add",
                element: (
                  <React.Suspense fallback={<VuiLoadingScreen />}>
                    <WhenTimeToVisitForm />
                  </React.Suspense>
                ),
              },
              {
                path: ":id",
                element: (
                  <React.Suspense fallback={<VuiLoadingScreen />}>
                    <WhenTimeToVisitForm />
                  </React.Suspense>
                ),
              },
            ],
          },
        ],
      },
      {
        path: "offer",
        children: [
          {
            path: "",
            element: (
              <React.Suspense fallback={<VuiLoadingScreen />}>
                <OfferList />
              </React.Suspense>
            ),
          },
          {
            path: "add",
            element: (
              <React.Suspense fallback={<VuiLoadingScreen />}>
                <OfferForm />
              </React.Suspense>
            ),
          },
          {
            path: ":id",
            element: (
              <React.Suspense fallback={<VuiLoadingScreen />}>
                <OfferForm />
              </React.Suspense>
            ),
          },
          {
            path: "category",
            children: [
              {
                path: "",
                element: (
                  <React.Suspense fallback={<VuiLoadingScreen />}>
                    <OfferCategoryList />
                  </React.Suspense>
                ),
              },
              {
                path: "add",
                element: (
                  <React.Suspense fallback={<VuiLoadingScreen />}>
                    <OfferCategoryForm />
                  </React.Suspense>
                ),
              },
              {
                path: ":id",
                element: (
                  <React.Suspense fallback={<VuiLoadingScreen />}>
                    <OfferCategoryForm />
                  </React.Suspense>
                ),
              },
            ],
          },
        ],
      },
      {
        path: "data-master",
        children: [
          {
            path: "continent",
            children: [
              {
                path: "",
                element: (
                  <React.Suspense fallback={<VuiLoadingScreen />}>
                    <ContinentList />
                  </React.Suspense>
                ),
              },
              {
                path: "add",
                element: (
                  <React.Suspense fallback={<VuiLoadingScreen />}>
                    <ContinentForm />
                  </React.Suspense>
                ),
              },
              {
                path: ":id",
                element: (
                  <React.Suspense fallback={<VuiLoadingScreen />}>
                    <ContinentForm />
                  </React.Suspense>
                ),
              },
            ],
          },
          {
            path: "country",
            children: [
              {
                path: "",
                element: (
                  <React.Suspense fallback={<VuiLoadingScreen />}>
                    <CountryList />
                  </React.Suspense>
                ),
              },
              {
                path: "add",
                element: (
                  <React.Suspense fallback={<VuiLoadingScreen />}>
                    <CountryForm />
                  </React.Suspense>
                ),
              },
              {
                path: ":id",
                element: (
                  <React.Suspense fallback={<VuiLoadingScreen />}>
                    <CountryForm />
                  </React.Suspense>
                ),
              },
            ],
          },
          {
            path: "city",
            children: [
              {
                path: "",
                element: (
                  <React.Suspense fallback={<VuiLoadingScreen />}>
                    <CityList />
                  </React.Suspense>
                ),
              },
              {
                path: "add",
                element: (
                  <React.Suspense fallback={<VuiLoadingScreen />}>
                    <CityForm />
                  </React.Suspense>
                ),
              },
              {
                path: ":id",
                element: (
                  <React.Suspense fallback={<VuiLoadingScreen />}>
                    <CityForm />
                  </React.Suspense>
                ),
              },
            ],
          },
          {
            path: "partner",
            children: [
              {
                path: "",
                element: (
                  <React.Suspense fallback={<VuiLoadingScreen />}>
                    <PartnerList />
                  </React.Suspense>
                ),
              },
              {
                path: "add",
                element: (
                  <React.Suspense fallback={<VuiLoadingScreen />}>
                    <PartnerForm />
                  </React.Suspense>
                ),
              },
              {
                path: ":id",
                element: (
                  <React.Suspense fallback={<VuiLoadingScreen />}>
                    <PartnerForm />
                  </React.Suspense>
                ),
              },
            ],
          },
          {
            path: "community",
            children: [
              {
                path: "",
                element: (
                  <React.Suspense fallback={<VuiLoadingScreen />}>
                    <CommunityList />
                  </React.Suspense>
                ),
              },
              {
                path: "add",
                element: (
                  <React.Suspense fallback={<VuiLoadingScreen />}>
                    <CommunityForm />
                  </React.Suspense>
                ),
              },
              {
                path: ":id",
                element: (
                  <React.Suspense fallback={<VuiLoadingScreen />}>
                    <CommunityForm />
                  </React.Suspense>
                ),
              },
            ],
          },
          {
            path: "why-experience",
            children: [
              {
                path: "",
                element: (
                  <React.Suspense fallback={<VuiLoadingScreen />}>
                    <WhyExperienceList />
                  </React.Suspense>
                ),
              },
              {
                path: "add",
                element: (
                  <React.Suspense fallback={<VuiLoadingScreen />}>
                    <WhyExperienceForm />
                  </React.Suspense>
                ),
              },
              {
                path: ":id",
                element: (
                  <React.Suspense fallback={<VuiLoadingScreen />}>
                    <WhyExperienceForm />
                  </React.Suspense>
                ),
              },
            ],
          },
          {
            path: "service",
            children: [
              {
                path: "",
                element: (
                  <React.Suspense fallback={<VuiLoadingScreen />}>
                    <ServiceList />
                  </React.Suspense>
                ),
              },
              {
                path: "add",
                element: (
                  <React.Suspense fallback={<VuiLoadingScreen />}>
                    <ServiceForm />
                  </React.Suspense>
                ),
              },
              {
                path: ":id",
                element: (
                  <React.Suspense fallback={<VuiLoadingScreen />}>
                    <ServiceForm />
                  </React.Suspense>
                ),
              },
            ],
          },
          {
            path: "customer-experience",
            children: [
              {
                path: "",
                element: (
                  <React.Suspense fallback={<VuiLoadingScreen />}>
                    <CustomerExperienceList />
                  </React.Suspense>
                ),
              },
              {
                path: "add",
                element: (
                  <React.Suspense fallback={<VuiLoadingScreen />}>
                    <CustomerExperienceForm />
                  </React.Suspense>
                ),
              },
              {
                path: ":id",
                element: (
                  <React.Suspense fallback={<VuiLoadingScreen />}>
                    <CustomerExperienceForm />
                  </React.Suspense>
                ),
              },
            ],
          },
        ],
      },
      {
        path: "luxury-resort",
        children: [
          {
            path: "",
            element: (
                <React.Suspense fallback={<VuiLoadingScreen />}>
                  <LuxuryResortList />
                </React.Suspense>
            ),
          },
          {
            path: "add",
            element: (
                <React.Suspense fallback={<VuiLoadingScreen />}>
                  <LuxuryResortForm />
                </React.Suspense>
            ),
          },
          {
            path: ":id",
            element: (
                <React.Suspense fallback={<VuiLoadingScreen />}>
                  <LuxuryResortForm />
                </React.Suspense>
            ),
          },
        ],
      },
      {
        path: "page",
        children: [
          {
            path: "",
            element: (
              <React.Suspense fallback={<VuiLoadingScreen />}>
                <PageList />
              </React.Suspense>
            ),
          },
          {
            path: ":id",
            element: (
              <React.Suspense fallback={<VuiLoadingScreen />}>
                <PageForm />
              </React.Suspense>
            ),
          },
        ],
      },
      {
        path: "contact",
        children: [
          {
            path: "luxury-resort",
            children: [
              {
                path: "",
                element: (
                  <React.Suspense fallback={<VuiLoadingScreen />}>
                    <ContactLuxuryResortList />
                  </React.Suspense>
                ),
              },
              {
                path: "add",
                element: (
                  <React.Suspense fallback={<VuiLoadingScreen />}>
                    <ContactLuxuryResortForm />
                  </React.Suspense>
                ),
              },
              {
                path: ":id",
                element: (
                  <React.Suspense fallback={<VuiLoadingScreen />}>
                    <ContactLuxuryResortForm />
                  </React.Suspense>
                ),
              },
            ],
          },
          {
            path: "offer",
            children: [
              {
                path: "",
                element: (
                  <React.Suspense fallback={<VuiLoadingScreen />}>
                    <ContactOfferList />
                  </React.Suspense>
                ),
              },
              {
                path: "add",
                element: (
                  <React.Suspense fallback={<VuiLoadingScreen />}>
                    <ContactOfferForm />
                  </React.Suspense>
                ),
              },
              {
                path: ":id",
                element: (
                  <React.Suspense fallback={<VuiLoadingScreen />}>
                    <ContactOfferForm />
                  </React.Suspense>
                ),
              },
            ],
          },
          {
            path: "journey",
            children: [
              {
                path: "",
                element: (
                  <React.Suspense fallback={<VuiLoadingScreen />}>
                    <ContactJourneyList />
                  </React.Suspense>
                ),
              },
              {
                path: "add",
                element: (
                  <React.Suspense fallback={<VuiLoadingScreen />}>
                    <ContactJourneyForm />
                  </React.Suspense>
                ),
              },
              {
                path: ":id",
                element: (
                  <React.Suspense fallback={<VuiLoadingScreen />}>
                    <ContactJourneyForm />
                  </React.Suspense>
                ),
              },
            ],
          },
        ],
      },
      {
        path: "setting",
        children: [
          {
            path: "company",
            element: (
              <React.Suspense fallback={<VuiLoadingScreen />}>
                <SettingCompany />
              </React.Suspense>
            ),
          },
          {
            path: "user",
            children: [
              {
                path: "",
                element: (
                  <React.Suspense fallback={<VuiLoadingScreen />}>
                    <SettingUserList />
                  </React.Suspense>
                ),
              },
              {
                path: "add",
                element: (
                  <React.Suspense fallback={<VuiLoadingScreen />}>
                    <SettingUserForm />
                  </React.Suspense>
                ),
              },
              {
                path: ":id",
                element: (
                  <React.Suspense fallback={<VuiLoadingScreen />}>
                    <SettingUserForm />
                  </React.Suspense>
                ),
              },
            ],
          },
          {
            path: "role",
            children: [
              {
                path: "",
                element: (
                  <React.Suspense fallback={<VuiLoadingScreen />}>
                    <SettingRoleList />
                  </React.Suspense>
                ),
              },
              {
                path: "add",
                element: (
                  <React.Suspense fallback={<VuiLoadingScreen />}>
                    <SettingRoleForm />
                  </React.Suspense>
                ),
              },
              {
                path: ":id",
                element: (
                  <React.Suspense fallback={<VuiLoadingScreen />}>
                    <SettingRoleForm />
                  </React.Suspense>
                ),
              },
            ],
          },
        ],
      },
    ],
  },
];

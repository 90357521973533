import { TFunction } from "react-i18next";
import { ReactNode } from "react";
import {
  ApartmentOutlined,
  BookOutlined,
  CopyOutlined,
  HomeOutlined,
  MailOutlined,
  PercentageOutlined,
  PushpinOutlined,
  SettingOutlined,
} from "@ant-design/icons";

export type MenuDataType = {
  title: string;
  key: string;
  access: string | string[];
  icon?: ReactNode;
  children?: MenuDataType[];
};

export const generateMenu = (t: TFunction): MenuDataType[] => {
  return [
    {
      title: t("common.text.dashboard"),
      key: "/",
      access: "any",
      icon: <HomeOutlined />,
    },
    {
      title: t("common.text.pages"),
      key: "/page",
      access: "pages.index",
      icon: <CopyOutlined />,
    },
    {
      title: t("common.text.destination"),
      key: "/destination-tab",
      access: [
        "destinations.index",
        "journeys.index",
        "best-experiences.index",
      ],
      icon: <PushpinOutlined />,
      children: [
        {
          title: t("common.text.list"),
          key: "/destination",
          access: "destinations.index",
        },
        {
          title: t("common.text.journey"),
          key: "/destination/journey",
          access: "journeys.index",
        },
        {
          title: t("common.text.bestExperience"),
          key: "/destination/best-experience",
          access: "best-experiences.index",
        },
        {
          title: t("common.text.whenTimeToVisit"),
          key: "/destination/when-time-to-visit",
          access: "when-time-to-visits.index",
        },
      ],
    },
    {
      title: t("common.text.luxuryResort"),
      key: "/luxury-resort",
      access: "luxury-resorts.index",
      icon: <BookOutlined />,
    },
    {
      title: t("common.text.dataMaster"),
      key: "/data-master-tab",
      access: [
        "continents.index",
        "countries.index",
        "cities.index",
        "partners.index",
        "communities.index",
        "why-experiences.index",
        "services.index",
        "customer-experiences.index",
      ],
      icon: <ApartmentOutlined />,
      children: [
        {
          title: t("common.text.continent"),
          key: "/data-master/continent",
          access: "continents.index",
        },
        {
          title: t("common.text.country"),
          key: "/data-master/country",
          access: "countries.index",
        },
        {
          title: t("common.text.city"),
          key: "/data-master/city",
          access: "cities.index",
        },
        {
          title: t("common.text.ourPartner"),
          key: "/data-master/partner",
          access: "partners.index",
        },
        {
          title: t("common.text.community"),
          key: "/data-master/community",
          access: "communities.index",
        },
        {
          title: t("common.text.whyExperience"),
          key: "/data-master/why-experience",
          access: "why-experiences.index",
        },
        {
          title: t("common.text.ourService"),
          key: "/data-master/service",
          access: "services.index",
        },
        {
          title: t("common.text.customerExperience"),
          key: "/data-master/customer-experience",
          access: "customer-experiences.index",
        },
      ],
    },
    {
      title: t("common.text.offers"),
      key: "/data-offer-tab",
      access: ["offer-categories.index", "offers.index"],
      icon: <PercentageOutlined />,
      children: [
        {
          title: t("common.text.list"),
          key: "/offer",
          access: "offers.index",
        },
        {
          title: t("common.text.category"),
          key: "/offer/category",
          access: "offer-categories.index",
        },
      ],
    },
    {
      title: t("common.text.contactForm"),
      key: "/data-contact-form-tab",
      access: [
        "contact-form-luxury-resorts.index",
        "contact-form-offers.index",
      ],
      icon: <MailOutlined />,
      children: [
        {
          title: t("common.text.journey"),
          key: "/contact/journey",
          access: "contact-form-journeys.index",
        },
        {
          title: t("common.text.luxuryResort"),
          key: "/contact/luxury-resort",
          access: "contact-form-luxury-resorts.index",
        },
        {
          title: t("common.text.offer"),
          key: "/contact/offer",
          access: "contact-form-offers.index",
        },
      ],
    },
    {
      title: t("common.text.setting"),
      key: "/setting",
      access: ["settings.show", "users.index", "roles.index"],
      icon: <SettingOutlined />,
      children: [
        {
          title: t("common.text.company"),
          key: "/setting/company",
          access: "settings.show",
        },
        {
          title: t("common.text.user"),
          key: "/setting/user",
          access: "users.index",
        },
        {
          title: t("common.text.role"),
          key: "/setting/role",
          access: "roles.index",
        },
      ],
    },
  ];
};

import { AxiosError } from "axios";
import { notification } from "antd";
import { IconType } from "antd/es/notification";
import _ from "lodash";
import { Permission } from "../../models/permission";
import moment from "moment";
import { TFunction } from "react-i18next";

export const getAliasesName = (value: string) => {
  try {
    const arrayName = value.split(" ");
    let aliasesName = value.charAt(0) + value.charAt(1);

    if (arrayName.length >= 2) {
      aliasesName = arrayName[0].charAt(0) + arrayName[1].charAt(0);
    }

    return aliasesName.toUpperCase();
  } catch (err) {
    return "?";
  }
};

export const openNotification = (
  type: IconType,
  message: string,
  description?: string
) => {
  notification[type]({
    message: message,
    description: description,
    placement: "top",
  });
};

export const handleBackendError = (e: AxiosError, msg: string = "") => {
  const err = e.response?.data as any;

  if (err && e.response?.status !== 404) {
    Object.keys(err).forEach((key) => {
      if (key !== "message") {
        let text = msg;
        if (!err[key] || err[key] === "Server Error") {
          text = msg;
        } else {
          text = err[key];
        }

        if (_.isObject(text)) {
          Object.keys(text).forEach((childKey) => {
            openNotification("error", _.get(text, `${childKey}`)[0]);
          });
        } else {
          openNotification("error", text);
        }
      }
    });
  } else {
    openNotification("error", msg);
  }
};

export const canAccess = (access: string | string[], permissions: string[]) => {
  if (typeof access === "string") {
    if (!permissions.includes(access) && access !== "any") {
      return false;
    }
  }

  if (Array.isArray(access)) {
    const tempPermissions: string[] = [];

    access.forEach((permission) => {
      if (permissions.includes(permission)) {
        tempPermissions.push(permission);
      }
    });

    if (tempPermissions.length <= 0) {
      return false;
    }
  }

  return true;
};

export const overrideTableSortOrder = (sortOrder: string) => {
  return sortOrder === "descend" ? "desc" : "asc";
};

export const mapPermissionToTable = (permissions: Permission[]) => {
  const baseData: any = {};
  const tableColumns: any[] = [
    {
      title: "",
      dataIndex: "name",
      key: "name",
      className: "text-capitalize",
    },
  ];

  permissions.forEach((permission) => {
    const name = permission.name.split(".")[0];
    if (baseData[name]) {
      baseData[name][permission.method] = permission.id;
    } else {
      Object.assign(baseData, {
        [name]: {
          [permission.method]: permission.id,
        },
      });
    }

    const isExist = tableColumns.find((item) => item.key === permission.method);

    if (!isExist) {
      tableColumns.push({
        title: permission.name.split(".")[1].replaceAll("-", " "),
        dataIndex: permission.method,
        key: permission.method,
        className: "text-capitalize",
      });
    }
  });

  const tableData = Object.keys(baseData).map((key) => ({
    name: key.replaceAll("-", " "),
    ...baseData[key],
  }));

  return {
    tableData,
    tableColumns,
  };
};

export const uiFilterData = {
  save: (key: string, val: any) =>
    localStorage.setItem(key, JSON.stringify(val)),
  get: (key: string) => JSON.parse(localStorage.getItem(key) || "[]"),
  remove: (key: string) => localStorage.removeItem(key),
  getSelectedFilter: (filterData: any[], selectedFilter: any[]) =>
    _.filter(filterData, (data) =>
      selectedFilter.includes(data?.id || data?.value)
    ),
};

export const formatFrontendDate = (date: string): any => {
  return moment(date).format("DD MMM YYYY");
};

export const formatBackendDate = (date: string): any => {
  return moment(date).format("YYYY-MM-DD");
};

export const formatCurrency = (number: number) => {
  const formatter = new Intl.NumberFormat("en-US", {
    currency: "IDR",
  });

  return formatter.format(number);
};

export const statusDefaultOptions = (t: TFunction) => {
  return [
    { id: 1, name: t("common.text.active") },
    { id: 0, name: t("common.text.notActive") },
  ];
};

export const monthDefaultOptions = (t: TFunction) => {
  return [
    { value: 1, label: t("month.january") },
    { value: 2, label: t("month.february") },
    { value: 3, label: t("month.march") },
    { value: 4, label: t("month.april") },
    { value: 5, label: t("month.may") },
    { value: 6, label: t("month.june") },
    { value: 7, label: t("month.july") },
    { value: 8, label: t("month.august") },
    { value: 9, label: t("month.september") },
    { value: 10, label: t("month.october") },
    { value: 11, label: t("month.november") },
    { value: 12, label: t("month.december") },
  ];
};

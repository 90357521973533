import { api, createCancelTokenHandler } from "../utils/services";
import { ENV } from "../constants";
import { ChangePasswordPayload } from "../modules/change-password/interface";

const AccountRepository = {
  updatePassword: function (
    payload: ChangePasswordPayload,
    params: any = null
  ) {
    return api.post(
      `${ENV.getApiEndPoint()}/api/account/update-password`,
      payload,
      {
        params,
        cancelToken:
          cancelTokenHandlerObject[
            this.updatePassword.name
          ].handleRequestCancellation().token,
      }
    );
  },
  notification: function (params: any = null) {
    return api.get(`${ENV.getApiEndPoint()}/api/notification`, {
      params,
      cancelToken:
        cancelTokenHandlerObject[
          this.notification.name
        ].handleRequestCancellation().token,
    });
  },
  showNotification: function (id: number | string, params: any = null) {
    return api.get(`${ENV.getApiEndPoint()}/api/notification/${id}`, {
      params,
      cancelToken:
        cancelTokenHandlerObject[
          this.showNotification.name
        ].handleRequestCancellation().token,
    });
  },
};

const cancelTokenHandlerObject = createCancelTokenHandler(AccountRepository);

export default AccountRepository;
